import { Injectable } from '@angular/core';
import { CookiePopupModalComponent } from './components/cookie-popup-modal/cookie-popup-modal.component';
import { AuthService } from '../auth/auth.service';
import { MatModalService } from '../mat-modal/mat-modal.service';
import { AppConfig } from '../../../config/app.config';

@Injectable({
  providedIn: 'root',
})
export class CookiePopupService {
  // make variable available in whole app
  cookieAccepted: boolean;
  isModalOpened = false;

  constructor(
    private authService: AuthService,
    private matModalService: MatModalService
  ) {
    const storedValue = localStorage.getItem(AppConfig.COOKIE_ACCEPTED_NAME);

    this.cookieAccepted = storedValue !== null ? storedValue === 'true' : null;
  }

  openDialog(disableBackdrop = false): void {
    if (!this.isModalOpened) {
      this.isModalOpened = true;

      this.matModalService
        .openDialog<boolean, boolean>(CookiePopupModalComponent, {
          position: {
            top: '0',
          },
          width: '100%',
          maxWidth: '100%',
          panelClass: 'cookie-popup',
          disableClose: true,
          hasBackdrop: !disableBackdrop,
        })
        .subscribe((accepted: boolean) => {
          const acceptedStr = accepted ? 'true' : 'false';

          localStorage.setItem(AppConfig.COOKIE_ACCEPTED_NAME, acceptedStr);
          this.cookieAccepted = accepted;

          if (accepted && this.authService.previousURL) {
            this.authService.redirectToPreviousUrlOrHome();
          }

          this.isModalOpened = false;
        });
    }
  }
}
